
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { CLIENT, ADMIN, CUSTOMER } from "@/constants";
import { rediredAsPerLoggedInUser } from "@/filters";
import SearchResults from "@/components/SearchResults.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "home",
  components: {
    Field,
    Form,
    ErrorMessage,
    SearchResults,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    let initTableData = ref([]);
    let viewSearchResults = ref(false);

    const submitButton = ref<HTMLButtonElement | null>(null);

    onMounted(() => {
      //check if current user is authenticated
      // if (store.getters.isUserAuthenticated) {
      //   rediredAsPerLoggedInUser(store, router);
      // }
    });

    //Create form validation object
    const login = Yup.object().shape({
      accused_first_name: Yup.string().required().label("First Name"),
      accused_last_name: Yup.string().required().label("Last Name"),
      accused_city: Yup.string().required().label("City"),
    });

    const fetchSearchResults = (options) => {
      return ApiService.query("report/mrbSearch", { params: options })
      .then((response: any) => {
        initTableData.value = response.data.results;
        viewSearchResults.value = true;
        console.log(response);
      })
      .catch(({ response }) => {
        viewSearchResults.value = false;
        Swal.fire({
          text: response,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });
    }

    //Form submit function
    const onSubmitLogin = async (values) => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      await fetchSearchResults(values);

      //Deactivate indicator
      if (submitButton.value) {
        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }      
    };

    const updateSearchResults = () => {
      viewSearchResults.value = false;
    }

    return {
      onSubmitLogin,
      login,
      submitButton,
      initTableData,
      viewSearchResults,
      updateSearchResults,
    };
  },
});
