
import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { formatDate } from "@/filters";

interface IClient {
  id: number;
  first_name: string;
  last_name: string;
  report_category: string;
  place_of_incidence: string;
  date_of_incidence: string;
}

export default defineComponent({
  name: "search-results",
  props: {
    initTableData: [],
  },
  components: {
    Datatable,
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: "First Name",
        key: "first_name",
        sortable: true,
      },
      {
        name: "Last Name",
        key: "last_name",
        sortable: true,
      },
      {
        name: "Date of Birth",
        key: "date_of_birth",
        sortable: true,
      },
      {
        name: "Phone",
        key: "phone",
        sortable: true,
      },
      {
        name: "Address",
        key: "city",
        sortable: false,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = ref<Array<IClient>>([]);
    const initCustomers = ref<Array<IClient>>([]);    

    const deleteFewCustomers = () => {
      checkedCustomers.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCustomers.value.length = 0;
    };

    const deleteCustomer = (id) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if (tableData.value[i].id === id) {
          tableData.value.splice(i, 1);
        }
      }
    };

    const reviewForm = (id, event) => {
      event.preventDefault();
      router.push({ name: "mrbSearch-viewReport", params: { peopleReportId: id } });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<IClient> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    const anotherSearch = () => {
      emit("updateSearchResults");
    }

    watch(
      () => props.initTableData,
      (curVal, oldVal) => {
        if(curVal != oldVal) {
          tableData.value = [];
          tableData.value = props.initTableData as any;        
        }
      },
      { immediate: true }
    )

    return {
      tableData,
      tableHeader,
      deleteCustomer,
      search,
      searchItems,
      checkedCustomers,
      deleteFewCustomers,
      reviewForm,
      formatDate,
      anotherSearch,
    };
  },
});
